import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 8,
  zoom: 75,
  mouse: [0, 0],
  z:0,
  introduction: {
    offset: 1,
    factor: 1.75,
    header: "hi, i'm adi!",
    image: "/me.jpg",
    aspect: 1.51,
    text: ""
   },
  experience: {
    offset: 2,
    factor: 1.75,
    header: "Experience",
    aspect: 2.0,
    sections: [
      { employer: "DaoAI Robotics", employerLink:"https://www.daoai.com/", title: "Computer Vision Engineer", duration: "Jan - Aug 2022",technologies: ["C/C++","Python","Pytorch","OpenCV","Docker","MIL","libgit2", "Qt"], description:"<ul><li>Improved computer vision algorithms and routines for the Bin Picking system and developed a DaoAI annotation tool.</li><li>Designed and implemented the Snapshot version control feature for Bin Picking software, Engineered a custom built C++ API for libgit2 to provide git-like snapshot functionality.</li><li>Developed and maintained DaoAI Bin Picking software, including new feature development, codebase maintenance, and enhancing existing functionality.</li><li>Collaborated with the QA team to identify and resolve bugs.</li></ul>"},
      { employer: "ICBC", employerLink:"", title: "RPA Engineer", duration: "May - Aug 2021",technologies: ["Python","SpaCy NLP", "Tesseract OCR", "Flask","SQL","Docker","Linux"], description:"<ul><li>Pioneered an End-to-End internal API for extracting names from documents improving document processing speeds by 30% .</li><li>Developed End-to-End automation solutions using BluePrism as per requirements which involves analysis, designing, development, testing, and deployment of automation.</li><li>Collaborated with Senior team members for the maintenance of existing automation solutions.</li><li>Worked in an Agile software development environment, including hosting and contributing to code reviews.</li></ul>"},
      { employer: "picoTera", employerLink:"https://www.picotera.co/", title: "Software Engineer", duration: "Jan - Apr 2021",technologies: ["C","Java","Android","Bluetooth LE","pSoC Creator"], description:"<ul><li>Developed firmware for patented hearing protection devices with Bluetooth Low Energy (BLE) technology.</li><li>Applied Machine Learning techniques to enhance noise cancellation and sound detection capabilities.</li><li>Led the development of various features for the accompanying Android app.</li><li> Implemented rigorous unit testing to meet industry standards and specifications.</li><li>Collaborated with cross-functional teams to deliver a high-quality, user-friendly product.</li></ul>"},
    ]
  },
  projects: {
    header: "Projects",
    sections: [
      { projectName: "Recall Rehearsal", winner:"StormHacks 2024 Winner 🥇", githubLink:"https://github.com/orgs/RecallRehearsal/repositories", ytLink:"https://www.youtube.com/watch?v=JYFQEud57zg",imLink:"https://raw.githubusercontent.com/RecallRehearsal/stormhacks-backend/main/promo/img.png",relevantLink: "", devpostLink:"https://devpost.com/software/recall-rehearsal",technologies: ["Langchain", "Python", "FastAPI", "ChromaDB", "GPT-4o", "Unity", "Oculus VR", "C#", "React", "Typescript", "Spline", "three.js"], description:`<b>Recall Rehearsal</b> is an innovative study VR application designed to enhance learning using the <b>Feynman technique</b>, emphasizing teaching others to identify knowledge gaps. The project features a engaging front-end built with React Three Fiber that allows users to upload notes for processing. Our backend, developed with Python and FastAPI, leverages LangChain and ChromaDB for efficient PDF parsing and tokenization. The backend integrates a personalized <b>RAG</b> pipeline to optimize OpenAI's GPT-4 for context-aware feedback and question generation.<br><br>Once processing is complete, users are directed to a Unity-built VR app that provides an immersive environment for active recall and rehearsal.<hr/><ul><li>Developed an immersive VR study app using the Feynman learning technique with a front-end for note uploading, a context-aware backend, and a VR review environment. </li><li>Built a robust backend with Python, FastAPI, Langchain, ChromaDB, and a personalized RAG model to optimize OpenAI’s GPT-4.</li><li>Created an interactive web front-end with React, React Three Fiber, JavaScript, and custom 3D models from Spline and Blender</li><li>Developed a VR experience using Unity for effective, immersive learning</li></ul>`},
      { projectName: "BuildFinder.net", winner:"", githubLink:"", ytLink:"",imLink:"",relevantLink: "", devpostLink:"",technologies: ["NextJS","Vercel","Supabase","AWS S3","PF2E","TailwindCSS","RadixUI"], description:"Coming Soon 👀"},
      { projectName: "The Council", winner:"Hack the North 2023 Winner 🥇", githubLink:"https://github.com/TheTripleA2023/theCouncilApp", ytLink:"",imLink:"https://raw.githubusercontent.com/TheTripleA2023/theCouncilApp/main/promo/Screenshot%202023-10-08%20at%202.53.21%20AM.png",relevantLink: "https://thecouncil.vercel.app/login", devpostLink:"https://devpost.com/software/the-council",technologies: ["NextJS","Three.js","React","OpenAI API","Vercel","Supabase","PostGreSQL","ChakraUI"], description:`We have to make a lot of decisions all the time- whether it's choosing your next hackathon project idea, texting your ex or not, writing an argumentative essay, or settling a debate. Sometimes, you need the cold hard truth. Sometimes, you need someone to feed into your delusions. But sometimes, you need both!<br/><br/>Give <b>the Council</b> your problem, and it'll answer with four (sometimes varying) AI-generated perspectives! With 10 different personalities to choose from, you can get a bunch of (imaginary) friends to weigh in on your dilemmas, even if you're all alone!<hr/><ul><li>Built a web app that utilizes OpenAI to create a "council" of unique AI personalities providing advice on dilemmas and problems.</li><li>Developed a RESTful API using Next.js API routes, OpenAI API, and Supabase for GPT queries, user authentication, and analytics.</li><li>Built front-end with React and front-end 3D components using Three.js</li></ul>` },
      { projectName: "NLP Name Recognition Microservice", winner:"", githubLink:"", ytLink:"",imLink:"",relevantLink: "", devpostLink:"",technologies: ["Python","SpaCy NLP","Tesseract OCR","Flask","SQL","Docker"], description:"<ul><li>Developed an efficient end-to-end NLP micro-service to extract entity data from text data, capable of processing thousands of documents daily significantly improving document processing efficiency at ICBC.</li><li>Utilized Google’s Tesseract OCR engine and a custom NLP model with sPaCy, achieving <b>94%</b> entity recognition accuracy on a 3000 document production test set.</li><li>Built and deployed REST API using Flask to wrap the NLP model, optimized response times from 3 seconds to 0.15 seconds.</li></ul>" },
      { projectName: "Chew Chew", winner:"WaffleHacks 2023 Finalist", githubLink:"https://github.com/HelthGoUp/ChewChew", ytLink:"https://www.youtube.com/watch?v=lZVBrbcwgFo",imLink:"https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/002/516/841/datas/original.png",relevantLink: "", devpostLink:"https://devpost.com/software/chew-chew",technologies: ["React","NodeJS","Firebase","ExpressJS","OpenAI API","Spoonicular API"], description:"Introducing <b>Chew Chew</b>, a website aimed to support students facing food insecurity in two major ways. <br/><br/>First, <b>Chew Chew</b> allows students efficiently utilize their food by letting them input leftover ingredients they have on hand and generating recipe suggestions for those ingredients. By doing this, we enable students in reducing food waste and learning recipes for nutritious, home-cooked meals. <br/><br/>Second, <b>Chew Chew’s</b> Food Forum promotes meal sharing- both socially and physically. Students can view and share posts of their home-cooked meals in an online community. <hr/><ul><li>Built a web app that lets students input leftover ingredients and generate cost-effective recipes from those ingredients. Included a forum for sharing recipes and free food postings on campus.</li><li>Developed the full-stack app using the <b>FERN</b> stack and integrated ChatGPT for personalized recipe recommendations</li><li>Implemented user and food forum pages using Firebase for database and authentication.</li></ul>" },
      { projectName: "UPC", winner:"", githubLink:"https://github.com/adipoluri/UPC", ytLink:"https://www.youtube.com/watch?v=fLkCDJBTgAQ",imLink:"https://raw.githubusercontent.com/adipoluri/UPC/main/promo/navigation.png",relevantLink: "", devpostLink:"https://devpost.com/software/upc",technologies: ["NextJS","Typescript","Supabase","MappedIn API", "auth0","mapbox","React","RadixUI"], description:"<b>UPC</b> is the ultimate restroom locator webapp designed with responsiveness and convenience in mind for those stressful moments where you really need to go. Quickly find the nearest male, female, or gender-neutral bathrooms with additional information about the cleanliness, availability, and other important services such as stocked Menstrual Products. Never visited the building before? Thats fine as we integrated mappedin interior navigation to guide you safely to your golden throne. Say goodbye to restroom hunting and save your time with UPC, your ultimate privy compass!" },
      { projectName: "AI Self Driving Car", winner:"", githubLink:"", ytLink:"https://www.youtube.com/watch?v=WbInvB3xzDs&t=19s&ab_channel=AdiPoluri",imLink:"https://img.youtube.com/vi/WbInvB3xzDs/0.jpg",relevantLink: "", devpostLink:"",technologies: ["Python","OpenCV","TensorFlow","AlexNet"], description:"<ul><li>Designed and Implemented a lane tracking computer vision algorithm using OpenCV</li><li>Created a Game Control API with PyNput to control any car through commands and enable script control.</li><li>Constructed an algorithm that produces movement controls by deciding the best optimal movement per frame based off slope calculations derived from the lane tracking data.</li><li>Using Python, TensorFlow, and the AlexNet architecture, trained a CNN on 250,000 frames and corresponding movements to create a model that decides what the best movement choice is to increase precision of driving</li></ul>      " },
      { projectName: "TidBit", winner:"", githubLink:"https://github.com/adipoluri/tidbit", ytLink:"https://www.youtube.com/watch?v=4z1G7PfPPgA",imLink:"https://raw.githubusercontent.com/adipoluri/tidbit/main/social/banner.png",relevantLink: "", devpostLink:"",technologies: ["PlasmoJS","React","Firebase","OpenAI API"], description:"<b>TidBit</b> is the browser extension that makes finding answers and completing tasks a breeze. If you find yourself looking for tidbits of information during your day and wish you could just get answers, look no further than TidBit!<br/><br/> With <b>TidBit</b>, you can quickly and easily find answers to any question or find instructions for any task, all without leaving your current webpage. Whether you're researching a school project, trying to find a recipe, or looking for advice on what product to buy, TidBit has you covered.<hr/><ul><li>Created browser extension that houses ChatGPT in a compact and practical form at the edge of your browser</li><li>Designed, prompt engineered, and implemented various query modes for different use cases.</li><li>Built the front-end UI using React and Material UI.</li><li>Integrated Firebase for user authentication and services to store user information and query history.</li></ul>" },
      { projectName: "[REDACTED]", winner:"", githubLink:"https://github.com/adipoluri/redacted", ytLink:"https://www.youtube.com/watch?v=0CjtybXXuxo",imLink:"https://img.itch.zone/aW1hZ2UvMTIxMzgxNC83MDgyNTUyLnBuZw==/original/narFnC.png",relevantLink: "https://4d1games.itch.io/redacted", devpostLink:"",technologies: ["Unity","C#","Photon Networking","Blender"], description:"<b>[REDACTED]</b> is a fast-paced FPS Multiplayer deathmatch game. The aim of the game is to simply be better than your opponents. Go grab a few of your friends, create a lobby, and see who is built different!<hr/><ul><li>Designed and implemented player controller, player logic, weapon controller, physics, and netcode for a 1st person FPS multiplayer game using Unity and C# with PUN Networking API.</li><li>Developed Beta versions for user testing and published to itch.io after 4 testing versions.</li><li>Designed, modeled, and rigged unique custom models using Blender.</li></ul>" },
      { projectName: "Quizify", winner:"", githubLink:"https://github.com/adipoluri/quizifymusicgame", ytLink:"https://www.youtube.com/watch?v=OIWbxEkIubI",imLink:"https://img.youtube.com/vi/OIWbxEkIubI/0.jpg",relevantLink: "https://quizifymusicgame.herokuapp.com/", devpostLink:"",technologies: ["NodeJS","SocketIO","HTML/CSS","Spotify API"], description:"<ul><li>Designed and created a multiplayer quiz-style game using data from the Spotify API, with NodeJS and Socket.io.</li><li>Developed client and server architecture to support party rooms for friends to join each other’s lobbies and compete.</li><li>Implemented a global leaderboard to track the highest scoring users.</li><li>Hosted the web app on Heroku and submitted it for NWHacks 2022.</li></ul>" },
      { projectName: "Pixelov", winner:"", githubLink:"https://github.com/adipoluri/Pixelov", ytLink:"https://www.youtube.com/watch?v=5TPaRKhXH9M&ab_channel=AdiPoluri",imLink:"https://img.youtube.com/vi/5TPaRKhXH9M/0.jpg",relevantLink: "", devpostLink:"",technologies: ["Flutter", "dart", "Firebase", "HiveDB", "Android", "JSON"], description:"<b>Pixelov</b> is a fan-made Idle Game adaptation of 'Escape From Tarkov'. With features such as daily raids, crafting, and collections, Enjoy a simplistic Tarkov experience on the go. <hr/><ul><li>Designed and developed an “Idle” style mobile game based on the PC video game “Escape from Tarkov” using Flutter as a front-end framework and Firebase for authentication and account handling.</li><li>Created custom widgets to achieve Idle Game functionality. Created captivating mechanics that keep players yearning for rewards. Published custom widget templates for items to allow for other developers to contribute to the project!</li><li>Created reactive and intuitive UI using flutter. Abstracted multiple widgets into separate widgets to be reused in different projects. Published “Scrollable Menu” widget for use by other Developers.</li><li>Created User based account and game persistence using Firebase Firestore and Realtime Database. Used HiveDB for local persistence.</li></ul>" },
      { projectName: "Wordler", winner:"", githubLink:"https://github.com/adipoluri/Wordler", ytLink:"",imLink:"",relevantLink: "", devpostLink:"",technologies: ["Node.JS","Express","Discord API"], description:"<b>Wordler</b> is a discord Chat Bot that provides a fun wordle-style game for you and your friends to play!<hr/><ul><li>Created Discord Bot using NodeJS that allows multiple users to play the hit game wordle in their own discord server</li><li>Created server side handling for multiple user sessions and discord servers</li></ul>" },
      { projectName: "Hospital Record Manager", winner:"", githubLink:"", ytLink:"",imLink:"",relevantLink: "", devpostLink:"",technologies: ["Java", "Swing", "Junit"], description:"<ul><li>Designed and developed a database manager for Hospitals to manage patients, doctors, and appointments using Java</li><li>Developed a simplistic GUI using Java Swing to enable the users to interact with the database with ease</li><li>Implemented persistence by serialization using GSON and JSON</li><li>Used JUnit to thoroughly test program during development</li><li>Refactored program and tests using abstraction and parent classes to reduce coupling and increase cohesion greatly</li></ul>" },
    ]
  },
  palette: {
    textColor:"#ebddc3",
    accentColor:"#d1c5ad",
    singleColorMode:true,
    ballSingle:61,
    ballMultiple: [2,8,13,14,15,29,50,51, 53, 54, 61, 62, 66, 73, 95, 96] //16
  },
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 },
    { offset: 10.3, color: "#000", height: 12 }
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 14, factor: 4 },
  ],
  top: createRef(),
  ballCount:6,
}

export default state


//      { projectName: "", winner:"", githubLink:"", ytLink:"",imLink:"",relevantLink: "", devpostLink:"",technologies: [], description:""},
